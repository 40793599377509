import { MessagesToWidget } from '@/shared/SalesPilotConstants';
import { getEndpointUrl } from './utils';

const IFRAME_ID = process.env.IFRAME_ID;

function send(actionName, payload) {
  const iframe = document.getElementById(IFRAME_ID);
  const endpointUrl = getEndpointUrl();

  if (iframe) {
    iframe.contentWindow.postMessage(
      { action: actionName, payload },
      endpointUrl,
    );
  }
}

function changeIframePosition(right, bottom) {
  const iframe = document.getElementById(IFRAME_ID);

  if (iframe) {
    iframe.style.right = right;
    iframe.style.bottom = bottom;
  }
}

export const notifyHostWasResized = () => {
  send(MessagesToWidget.NOTIFY_HOST_WAS_RESIZED, window.innerWidth);
};

export const notifyScrollPosition = () => {
  send(MessagesToWidget.NOTIFY_SCROLL_POSITION, window.scrollY);
};

window.salesPilotPostMessages = {
  send,
  notifyHostWasResized,
  notifyScrollPosition,
  notifyUserHasLoggedIn: () => send(MessagesToWidget.NOTIFY_USER_HAS_LOGGED_IN),
  notifyUserHasLoggedOut: () =>
    send(MessagesToWidget.NOTIFY_USER_HAS_LOGGED_OUT),
  notifyUserHasSignedUp: () => send(MessagesToWidget.NOTIFY_USER_HAS_SIGNED_UP),
  notifyPendingUserWasCreated: () =>
    send(MessagesToWidget.NOTIFY_PENDING_USER_WAS_CREATED),
  changeIframePosition,
  openChatPage: () => send(MessagesToWidget.OPEN_CHAT_PAGE),
  openSearchPage: () => send(MessagesToWidget.OPEN_SEARCH_PAGE),
  collapse: () => send(MessagesToWidget.COLLAPSE),
  hide: () => send(MessagesToWidget.HIDE),
  show: () => send(MessagesToWidget.SHOW),
  reload: () => send(MessagesToWidget.RELOAD),
  addToCart: (payload) => send(MessagesToWidget.ADD_TO_CART, payload),
  buyNow: (payload) => send(MessagesToWidget.BUY_NOW, payload),
  createQuote: (payload) => send(MessagesToWidget.CREATE_QUOTE, payload),
  requestMultiplePartsPrices: (payload) =>
    send(MessagesToWidget.REQUEST_MULTIPLE_PARTS_PRICES, payload),
};
